/* eslint-disable camelcase */
import NextImage from "next/image";
import NextLink from "next/link";

import { ArrowRight } from "~/components/common/icons";
import type { WP_REST_API_Post } from "~/types/WordPress";
import { ImageWrapper } from "~/ui";

interface ArticleCardProps {
  data: WP_REST_API_Post;
}
export const ArticleRecommendedCarousel = ({ data }: ArticleCardProps) => {
  const {
    acf: { title, image },
  } = data;

  return (
    <NextLink
      className="relative  flex w-full grid-cols-1 flex-col overflow-hidden rounded-lg pb-[100%] shadow-ecPrimary-4"
      href={`/blog/${data?.slug}`}
    >
      <div className="">
        <span className="slider-image-span">
          <NextImage
            src={image || "/assets/images/product-image-placeholder.svg"}
            className=" w-full rounded-t-lg object-cover"
            //fill
            alt={title}
            //layout="fill"
            objectFit="cover"
            width={440}
            height={40}
            loading="lazy"
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8+/btfwAJZAO2zFgDiwAAAABJRU5ErkJggg=="
          />
        </span>
      </div>

      <div className="absolute bottom-0 flex w-full items-center justify-between rounded-b-lg bg-colorFourteen p-5 font-bold text-colorFifteen">
        <p className="w-[80%] flex-wrap sm:text-base lg:text-lg">{title}</p>
        <ArrowRight fill="rgb(203, 153, 114)" />
      </div>
    </NextLink>
  );
};
